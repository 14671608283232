import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Elizabeth Chan</h1>
      <p className="About">
        Fullstack web developer | Currently working at Maven Clinic
      </p>
      <p className="Reach">
        Reach out at
        {' '}
        <a href="mailto: elizabethmchan2@gmail.com">elizabethmchan2@gmail.com</a>
        {' '}
        or via
        {' '}
        <a target="_blank" href="https://www.linkedin.com/in/elizabeth-chan/">
         LinkedIn
        </a>
      </p>
    </div>
  );
}

export default App;
